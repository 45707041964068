$base-font:"Montserrat", sans-serif;

$white: #fff;
$black: #000;
$base: #21201f;
$alt: #f3a131;
$bgc: #222222;

$light: 300;
$normal: 400;
$medium:500;
$sbold: 600;
$bold: 700;
$xbold: 800;
